import { useDirectives, datemodel } from 'solid-utils/model';
import type { HistorySignal } from 'solid-utils/history';
import { get } from 'solid-utils/access';
import { Show, children } from 'solid-js';
import type { Component, JSX, ParentProps, Signal } from 'solid-js';
import Clock from 'i/interactive/Clock';

import { hasNoTime, hasTime, isDateInPast, today } from 'f/settings/units/date';

import { t } from 'f/create-button/locales';

import type { useDialog } from 'shared/ui/use-dialog';
import DatePicker from 'shared/ui/datepicker';

import Calendar from 'i/Calendar.svg';

export function PlanDateSelect(props: {
  dialogControl?: ReturnType<typeof useDialog>;
  planDate: HistorySignal<Date | null>;
  disabled?: boolean;
  icon?: JSX.Element;
  neutralText?: boolean;
  bottomChildren?: (timeModel: Signal<string>, dateModel: Signal<Date | null>) => JSX.Element;
  children?: JSX.Element | ((DefaultView: () => JSX.Element) => JSX.Element);
}) {
  useDirectives(datemodel(today()));

  const customView = children(() => typeof props.children === 'function' ? props.children(DefaultView) : props.children);

  return (
    <DatePicker model={props.planDate} bottomChildren={props.bottomChildren}
      clearText={t('datepicker clear')}
      cancelText={t('datepicker cancel')}
      acceptText={t('datepicker accept')}
      timeLabel={t('datepicker time')}
      disabled={props.disabled}
      dialogControl={props.dialogControl}
    >
      <Show when={!props.children} fallback={customView()}>
        <DefaultView />
      </Show>
    </DatePicker>
  );

  function DefaultView() {
    return <div class="= flex gap-1 items-center">
      <Show when={!props.icon} fallback={props.icon}>
        <Show when={get(props.planDate) && hasTime(get(props.planDate))}
          fallback={<Calendar
            class="= w-6 h-6"
            classList={{
              'fill-urgent!': !props.disabled && props.planDate.canUndo() && isDateInPast(get(props.planDate)),
              'fill-tg_button': !props.disabled && props.planDate.canUndo() && !isDateInPast(get(props.planDate)),
              'fill-tg_hint': !props.planDate.canUndo() || props.disabled,
            }} />}
        >
          <Clock time={get(props.planDate)}
            class="= w-8 h-8 m--1"
            classList={{
              'stroke-urgent fill-urgent!': !props.disabled && props.planDate.canUndo() && isDateInPast(get(props.planDate)),
              'stroke-tg_button fill-tg_button': !props.disabled && props.planDate.canUndo() && !isDateInPast(get(props.planDate)),
              'stroke-tg_hint fill-tg_hint': !props.planDate.canUndo() || props.disabled,
            }} />
        </Show>
      </Show>
      <span class="= app-text-subtitle font-600"
        classList={{
          'c-tg_text!': props.neutralText && props.planDate.canUndo(),
          'c-urgent': !props.disabled && props.planDate.canUndo() && isDateInPast(get(props.planDate)),
          'c-tg_button': !props.disabled && props.planDate.canUndo() && !isDateInPast(get(props.planDate)),
          'c-tg_hint': !props.planDate.canUndo() || props.disabled,
        }}
      >
        {hasNoTime(get(props.planDate))
          ? t('new-task input-date', { date: get(props.planDate) })
          : t('new-task input-date-time', { date: get(props.planDate) })}
      </span>
    </div>;
  }
}
