import WebApp from 'tma-dev-sdk';
import { model, useDirectives } from 'solid-utils/model';
import { createHistorySignal } from 'solid-utils/history';
import type { HistorySignal } from 'solid-utils/history';
import { get, set } from 'solid-utils/access';
import { Show, children, createRenderEffect, createUniqueId, on } from 'solid-js';
import type { JSX, ParentProps, Ref, Signal } from 'solid-js';

import { isMobile } from 'shared/platform';
import { currentLocale } from 'shared/l10n';

import { hasTime, withTime, withConfigTimezone, hasNoTime, today, fromConfigTimezone } from 'f/settings/units/date';

import { useDialog } from '../use-dialog';
import { MainButton, SecondaryButton } from '../telegram';
import Dialog from '../dialog';

import DatepickerStandalone from './datepicker-standalone';

import Plus from 'i/Plus.svg';

export default function DatePicker(props: ParentProps<{
  dialogControl?: ReturnType<typeof useDialog>;
  model: Signal<Date | null>;
  disabled?: boolean;
  clearText?: string;
  timeLabel?: string;

  cancelText: string;
  acceptText: string;

  bottomChildren?: (timeModel: Signal<string>, dateModel: Signal<Date | null>) => JSX.Element;
}>) {
  const localModel = createHistorySignal<Date | null>(getSelectionObject(get(props.model)));
  const timeModel = createHistorySignal(getStringFromTime(props.model));

  createRenderEffect(on(() => get(props.model),  () => {
    if (localModel.canUndo()) {
      WebApp.HapticFeedback.selectionChanged();
    }

    localModel.reset(getSelectionObject(get(props.model)));
    timeModel.reset(getStringFromTime(props.model) ?? '');
  }));

  const [isOpen, setOpen, setRef, ref] = props.dialogControl ?? useDialog('modal');

  useDirectives(model);

  const acceptDate = () => {
    applyDateFromLocalModel(get(timeModel), get(localModel), props.model);
    setOpen(false);
  };

  let timeInput!: HTMLInputElement;

  const clearAndAcceptDate = () => {
    set(localModel, null);
    timeModel.reset();
    timeInput.value = '';
    acceptDate();
  };

  const bottomChildren = children(() => props.bottomChildren?.(timeModel, localModel));

  return (<>
    <button onClick={(e) => props.disabled || (e.stopPropagation(), setOpen(true))}
      type="button"
      class="=open-datepicker relative bg-transparent cursor-pointer p-0"
      disabled={props.disabled}
    >
      {props.children}
    </button>

    <Dialog dialogParams={[isOpen, setOpen, setRef, ref]}
      class="=datepicker-dialog fixed m-auto p-0 rounded-3 bg-tg_bg border-0 max-h-full max-w-full min-w-50"
      classList={{ 'dark': WebApp.colorScheme === 'dark' }}
    >
      <Show when={props.clearText}>
        <div class="=clear-date px-5 pt-3 pb-1 flex justify-end">
          <button onClick={clearAndAcceptDate}
            type="button"
            class="= app-text-smaller opacity-70 bg-transparent c-tg_text"
          >
            {props.clearText}
          </button>
        </div>
      </Show>

      <DatepickerStandalone today={withConfigTimezone(today())}
        value={localModel}
        locale={currentLocale()}
      />

      <Show when={props.timeLabel}>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <TimeInput timeLabel={props.timeLabel!} timeModel={timeModel} ref={timeInput} />
      </Show>

      {bottomChildren()}

      <Show when={isOpen()}>
        <SecondaryButton onClick={() => (setOpen(false), set(localModel, getSelectionObject(get(props.model))))}
          text={props.cancelText}
        >
        </SecondaryButton>

        <MainButton onClick={acceptDate}
          text={props.acceptText}
        >
        </MainButton>
      </Show>
    </Dialog>
  </>);

  function getSelectionObject(date: Date | null): Date {
    if (!date) {
      return today();
    }

    if (hasNoTime(date)) {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
      );
    }

    // Pretend that Local === Current Setting timezone
    return withConfigTimezone(new Date(date));
  }
}

export function applyDateFromLocalModel(timeText: string, dateSource: Date | null, targetModel: Signal<Date | null>) {
  if (!dateSource) {
    return set(targetModel, null);
  }

  const timeDate = new Date(
    dateSource.getFullYear(),
    dateSource.getMonth(),
    dateSource.getDate(),
  );

  const [hours, minutes] = timeText?.split(':').map(v => Number(v)) ?? [0, 0];

  if (isNaN(hours) || isNaN(minutes)) {
    // "No time" means 00:00 in UTC
    timeDate.setMinutes(-timeDate.getTimezoneOffset(), 0, 0);
    return set(targetModel, timeDate);
  }

  set(targetModel, withTime(timeDate, Number(hours), Number(minutes), fromConfigTimezone));
}

export function getStringFromTime(timeModel: Signal<Date | null>) {
  return hasTime(get(timeModel)) ? get(timeModel)?.toLocaleTimeString('en', {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  }) ?? '' : '';
}

export function TimeInput(props: {
  timeLabel: string;
  timeModel: HistorySignal<string>;
  ref?: Ref<HTMLInputElement>;
}) {
  const inputTimeId = `${createUniqueId()}-time`;
  let timeInput!: HTMLInputElement;

  return <div class="=time-input-container px-5 max-w-full flex justify-between items-center overflow-hidden mb-4">
    <label class="=time-label c-tg_hint flex items-center" for={inputTimeId}>
      {props.timeLabel}
      <Show when={shouldShowTimeClearButton()}
        fallback=":"
      >
        <span role="button" onClick={() => (props.timeModel.reset(), timeInput.value = '')}
          class="=clear-time ltr:ml-2 rtl:mr-2 cursor-pointer"
        >
          <Plus class="= fill-tg_hint rotate-45 w-4" />
        </span>
      </Show>
    </label>

    <input type="time" id={inputTimeId} ref={e => (typeof props.ref === 'function' ? props.ref!(e) : props.ref = e, timeInput = e)}
      class="=time-input min-w-16 max-w-40% h-10 ltr:ml-4 rtl:mr-4 text-center b-solid b-1 b-tg_hint rounded-2 cursor-text"
      pattern="[0-9]{2}\:[0-9]{2}"
      use:model={props.timeModel}
      onClick={e => e.stopPropagation()}
    />
  </div>;

  function shouldShowTimeClearButton(): boolean | null | undefined {
    return !isMobile() && !!get(props.timeModel) && !!get(props.timeModel)?.split(':').some(x => !isNaN(Number(x)));
  }
}
