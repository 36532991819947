import { datemodel, useDirectives } from 'solid-utils/model';
import type { HistorySignal } from 'solid-utils/history';
import { get } from 'solid-utils/access';
import { Show } from 'solid-js';
import type { JSX, ParentProps } from 'solid-js';

import { hasNoTime, isDateEqual, isDateInPast, today, tomorrow } from 'f/settings/units/date';

import { t } from 'f/create-button/locales';

import DatePicker from 'shared/ui/datepicker';

import GreyFlag from 'i/GreyFlag.svg';
import Flag from 'i/Flag.svg';

export function DueDateSelect(props: ParentProps<{
  dueDate: HistorySignal<Date | null>;
  disabled?: boolean;
  icon?: JSX.Element;
  neutralText?: boolean;
  hideDate?: boolean;
}>) {
  useDirectives(datemodel);

  return <DatePicker
    disabled={props.disabled}
    model={props.dueDate}

    clearText={t('datepicker clear')}
    cancelText={t('datepicker cancel')}
    acceptText={t('datepicker accept')}
    timeLabel={t('datepicker time')}
  >
    <Show when={!props.children} fallback={props.children}>
      <div class="= flex gap-1 items-center justify-end" classList={{ 'min-h-8 min-w-8': !props.icon }}>
        <Show when={get(props.dueDate) && props.dueDate.canUndo()}
          fallback={<Show when={!props.icon} fallback={props.icon}><GreyFlag /></Show>}
        >
          <Show when={!props.icon} fallback={props.icon}>
            <Flag class="= min-h-8 min-w-8"
              classList={{
                'fill-urgent!': !props.disabled && (isDateEqual(get(props.dueDate), today()) || isDateInPast(get(props.dueDate))),
                'fill-tg_button': !props.disabled && isDateInPast(today(), get(props.dueDate) ?? tomorrow()),
                'fill-tg_hint': props.disabled,
              }}
            />
          </Show>
          <Show when={!props.hideDate}>
            <span class="= app-text-subtitle font-600 text-nowrap"
              classList={{
                'c-tg_text!': props.neutralText && props.dueDate.canUndo(),
                'c-urgent': !props.disabled && (isDateEqual(get(props.dueDate), today()) || isDateInPast(get(props.dueDate))),
                'c-tg_button': !props.disabled && isDateInPast(today(), get(props.dueDate) ?? tomorrow()),
                'c-tg_hint': props.disabled,
              }}
            >
              {hasNoTime(get(props.dueDate))
                ? t('new-task input-due-date', { date: get(props.dueDate) })
                : t('new-task input-due-date-time', { date: get(props.dueDate) })}
            </span>
          </Show>
        </Show>
      </div>
    </Show>
  </DatePicker>;
}
